import React from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import Store from './services/Store'
import { registerToken } from './services/auth.js'

import Avatar from './img/avatar.png'
import Couple from './img/couple.png'

let checkInterval = null

let messagesStore = null
let messagesId = null
export const getMessagesStore = id => {
  if (messagesStore && messagesId === id) return messagesStore
  messagesId = id
  messagesStore = new Store(`
  {
    messages: search(type: "message", source: "database", filter: "channel_id:${id}") {
      ... on Message {
        id
        content
      }
    }
  }`)
  return messagesStore
}

let currentStore = null
export const getStore = () => {
  if (currentStore) return currentStore
  currentStore = new Store(`
  {
    me {
      id
      channels {
        id
        indexType
        name
        active
        group_id
        public
        message_count
        users_json
      }
    }
  }`)
  return currentStore
}

const StartChat = observer(props => {
  return <div
    onClick={props.openBox ? props.sendMessage : props.openMessageBox}
    className='rounded-full text-blue-dar bg-white hover:bg-blue text-blue-dark hover:text-white flex items-center text-2xl hover:text-4xl justify-center cursor-pointer border-2 border-blue-dark shadow-lg z-50'
    style={{position: 'fixed',bottom: 50, right: 30, width: '60px', height: '60px'}}>
      <i className={props.openBox ? 'fas fa-play' : 'fas fa-comment-alt'} />
  </div>
})

const MessageBox = observer(props => {
  const chatStarted = props.store && props.store.data ? props.store.data.messages : false
  const messages = chatStarted && props.store.data.messages[0] ? props.store.data.messages : []
  const animate = (props.showRegistration || chatStarted) ? 'animateChat' : ''
  const userRegistered = props.store
  const userName = window.localStorage.getItem('userName')

  if (checkInterval) clearInterval(checkInterval)
  if (props.store) checkInterval = setInterval(() => {
    props.store.reload()
  }, 5000)
  // console.log(messages)
  return <div
    className={`shadow-lg rounded-lg flex flex-col justify-between bg-blue-dark text-white ${animate}`}
    style={{position: 'fixed',bottom: 30, right: 50, width: '400px', height: '400px'}}>
    <div className='flex justify-between' style={{ padding: chatStarted ? '15px' : '30px' }}>
      <div className='flex' style={{ fontSize: chatStarted ? '30px' : '42px' }}>
        {chatStarted && <img alt='avatar' style={{ width: '60px', height: '60px', marginRight: '20px' }} src={Couple} /> }
        <div style={{ alignSelf: 'center' }}>Witaj! {chatStarted && userName}</div>
      </div>
      <div onClick={props.closeMessageBox} className='rounded-full cursor-pointer self-start hover:bg-blue' style={{ padding: '5px' }}>
        <i className='fal fa-times' style={{ fontSize: '20px' }} />
      </div>
    </div>
    {!userRegistered && props.showRegistration &&
      <div className='bg-white text-black border-b border-blue-dark' style={{ padding: '30px', height: '410px' }}>
        <form className='rounded-lg bg-grey-light' style={{ padding: '10px '}} onSubmit={(e) => {
            e.preventDefault()
            props.register()
          }}>
          <div style={{ marginBottom: '10px' }}>
            <div style={{ marginBottom: '10px' }}>Przedstaw się:</div>
            <label>
              * Imię:
              <br />
              <input type='text' style={{background: 'white', color: 'black'}} className='border' value={props.userName} name='userName' required onChange={props.registerHadler} />
            </label>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <label>
              * E-mail:
              <br />
              <input type='email' style={{background: 'white', color: 'black'}} className='border' value={props.email} name='email' required onChange={props.registerHadler} />
            </label>
          </div>
            <button type='submit' className='bg-blue hover:bg-blue-dark text-white font-bold py-2 px-4 rounded'>
              Ropocznij czat
            </button>
            <button type='button' onClick={props.closeMessageBox} className='bg-red hover:bg-blue-dark text-white font-bold py-2 px-4 rounded' style={{ marginLeft: '5px' }}>
              Anuluj
            </button>
        </form>
      </div>
    }
    {userRegistered && chatStarted &&
      <div className='bg-white text-black border-b border-blue-dark' id='scroll' style={{ padding: '30px 30px 0 30px', overflowY: 'auto', height: '410px' }}>
        {messages.slice().reverse().map((messages, i) => {
          if (!messages) return null
          return <div key={i}> {JSON.parse(messages.content).map((el, i) => {
            const messageClass = el.author === 'Firma' ? 'bg-blue-dark float-left' : 'bg-blue float-right'
            return <div key={i}>
              <div className={`rounded-lg text-white ${messageClass}`} style={{ padding: '5px 10px', marginBottom: '10px', maxWidth: '200px', wordWrap: 'break-word' }}>
                {el.content}
              </div>
              <div className='clearfix' />
            </div>
          })}
          </div>
        })
        }
      </div>
    }
    {!userRegistered && !props.showRegistration &&
      <React.Fragment>
        <div className='flex justify-between' style={{ padding: '32px 30px 0 30px' }}>
          <div style={{ lineHeight: '22px' }}>Potrzebujesz pomocy? Masz jakieś pytania? Chętnie odpowiemy</div>
          <img alt='couple' style={{ width: '100px', height: '100px' }} src={Avatar} />
        </div>
        <div className='bg-blue' style={{ padding: '30px' }}>
          <i className='fas fa-circle' style={{ color: '#58b743', marginRight: '8px' }} />
          Pisz śmiało, jesteśmy dostępni!
        </div>
      </React.Fragment>
    }

    <div className='rounded-b-lg' style={{ width: '100%', height: '100px', padding: '30px', background:  'white' }}>
      <form onSubmit={(e) => {
          e.preventDefault()
          props.sendMessage()
        }}>
        <input type='text' value={!chatStarted ? props.firstMessage : props.message} name={!chatStarted ? 'firstMessage' : 'message'} onChange={props.handleChange} placeholder='Wpisz treść wiadomości...' style={{background: 'transparent', color: 'black', outline: 'none' }}/>
      </form>
    </div>
  </div>
})


const Chat = observer(class _Chat extends React.Component {
  state = {
    openBox: false,
    message: '',
    status: false,
    chatStarted: false,
    showRegistration: false
  }

  openMessageBox = () => {
    this.setState({ openBox: true })
  }
  closeMessageBox = () => {
    this.setState({ openBox: false })
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }
  createChannel = () => {
    const { store } = this.props
    const urlParams = new URLSearchParams(window.location.search)
    store.executeMutation('createchannel', {
        name: `${this.props.email} - ${store.data.me.id}`,
        public: false,
        group_id: urlParams.get('org_id'),
        users_json: JSON.stringify([
          {id: store.data.me.id, message_count: 0}
        ])
      })
    store.feedback = id => {
      setTimeout(() => {
        this.setState({message: this.state.firstMessage}, () => {
          this.sendMessage()
        })
      }, 1000)
    }
  }
  sendMessage = () => {
    if (this.state.message) {
      if (this.state.message.length < 1) return
      this.props.store.executeMutation('sendMessage', {
        channel_id: this.props.store.data.me.channels[0].id,
        author: this.props.store.data.me.id,
        date: moment().format(),
        content: this.state.message
      })
      this.setState({
        message: ''
      }, () => {
        setTimeout(() => {
          const element = document.getElementById('scroll')
          if (element) element.scrollTop = element.scrollHeight
        }, 300)
      })
    } else if (this.state.firstMessage) {
      this.setState({ showRegistration: true })
    }
  }

  render () {
    const openBox = this.state.openBox ? 'box-show' : 'box'
    return <div>
      <StartChat sendMessage={this.sendMessage} openBox={this.state.openBox} openMessageBox={this.openMessageBox} />
      <div className={openBox}>
        <MessageBox
          store={this.props.store && this.props.store.data && this.props.store.data.me && this.props.store.data.me.channels[0] ? getMessagesStore(this.props.store.data.me.channels[0].id) : null}
          showRegistration={this.state.showRegistration}
          register={() => this.props.register(this.createChannel)}
          registerHadler={this.props.registerHadler}
          status={this.state.status}
          message={this.state.message}
          sendMessage={this.sendMessage}
          handleChange={this.handleChange}
          openBox={this.state.openBox}
          closeMessageBox={this.closeMessageBox}
          chatStarted={this.state.chatStarted}
          createChannel={this.createChannel} />
      </div>

    </div>
  }
})

const Register = class _Register extends React.Component {
  state = {
    email: ''
  }
  register = (createChannel) => {
    registerToken(this, createChannel)
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }
  render () {
    const userRegistered = window.localStorage.getItem('chatToken')
    return <Chat store={userRegistered ? getStore() : null} register={this.register} registerHadler={this.handleChange} email={this.state.email} />
  }
}
export default Register
